import { IRouteConfig } from "../interface";
import { compulsory_educations_routes } from "./compulsory_education";
import { groups_routes } from "./groups";
import { kpi_routes } from "./kpi";

const admin_child_routes: IRouteConfig[] = [
  {
    path: "/roles",
    name: "admission_roles",
    meta: {
      name: "Роли",
      check_jwt: true,
      permissions: ["admin"]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/admission/Roles.vue")
  },
  {
    path: "/skill_templates",
    name: "skill_templates",
    meta: {
      name: "Шаблоны навыков",
      check_jwt: true,
      permissions: ["admin"]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/skill_templates/Templates.vue"
      )
  },
  ...kpi_routes,
  ...compulsory_educations_routes,
  ...groups_routes
];

export const admin_routes: IRouteConfig[] = [
  {
    path: "/admin",
    name: "admin",
    meta: {
      name: "Настройки администратора",
      check_jwt: true,
      permissions: ["admin"]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Settings.vue"),
    children: admin_child_routes,
    redirect: { name: "admission_roles" }
  }
];
