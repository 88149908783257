import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { EDeviceTypes } from "@/enums/deviceTypes";
import { ISignInResponsePermissions } from "@/api/routes/auth";
import UserUser from "@/models/user/user";
import PositionLocalLevel from "@/models/position/level";
import PositionLocalLevelWorkplace from "@/models/position/level_workplace";
import SkillLocal from "@/models/skill/local";
import { IEvent } from "@/helpers/calendar_helper";
import { ImportJob } from "@/models/import/import_job";
import TestingFolder, { ITestingFolder } from "@/models/testing/folder";
import {
  IChangedTesting,
  IChangingTesting
} from "@/views/testings/components/TestingChange.vue";
import { IMovedFolder } from "@/views/testings/components/TestingsTree.vue";
import { IChangeArchiveTesting } from "@/views/testings/components/ChangeArchiveTesting.vue";
import { ESkillCategory } from "@/models/skill/category";
import { EProgramVersionStatus } from "@/models/program/version";
import {
  IChangedAktQuestion,
  IChangingAktQuestion
} from "@/views/akt/components/QuestionChange.vue";
import {
  IDeletedAdmissionRole,
  IDeletingAdmissionRole
} from "@/views/admission/components/RoleDelete.vue";
import {
  IChangedAdmissionRole,
  IChangingAdmissionRole
} from "@/views/admission/components/RoleChange.vue";
import { ITaskDelete } from "@/views/tasks/components/TaskDeleteIndividual.vue";
import {
  ITaskUpdate,
  IUpdatedIndividualTask
} from "@/views/tasks/components/TaskUpdateIndividual.vue";
import TaskTask from "@/models/task/task";
import { ITaskIndividualCreate } from "@/views/tasks/components/TaskCreateIndividual.vue";
import { ITaskGroupCreate } from "@/views/tasks/components/TaskCreateGroup.vue";
import { EAdmissionLevel } from "@/models/admission/permission";
import { IEditTreeUser } from "@/views/staffs_and_groups/staffs/Tree.vue";
import {
  IChangedSkillTemplate,
  IChangingSkillTemplate
} from "@/views/skill_templates/components/TemplateChange.vue";
import {
  IDeletedSkillTemplate,
  IDeletingSkillTemplate
} from "@/views/skill_templates/components/TemplateDelete.vue";
import {
  IChangedStructureFactory,
  IChangingStructureFactory,
  IDeletedStructureFactory
} from "@/views/structures/components/FactoryChange.vue";
import {
  IChangedStructureInfinity,
  IChangingStructureInfinity,
  IDeletedStructureInfinity
} from "@/views/structures/components/InfinityChange.vue";

export interface IStoreState {
  deviceType: EDeviceTypes;
  showNavMenu: boolean;
  authorized: boolean;
  currentUser: IJWTUser | null;
  currentUserPermissions: ISignInResponsePermissions[] | null;
  editTreeUser: IEditTreeUser | null;
  editedTreeUser: UserUser | null;
  factoryProgramId: string | null;
  openTreeProgram: Map<string, boolean>;
  editTreeLevel: PositionLocalLevel | null;
  editedTreeLevel: PositionLocalLevelWorkplace | null;
  editedTreeSkill: SkillLocal | null;
  editedSkillId: string | null;
  editedSkillIdElement: string | null;
  editedSkillLevel: number | null;
  editedSkillPrefix: string | null;
  editedSkillReload: number | null;
  search: string;
  rangeDate: string[];
  currentSkill: SkillLocal | null;
  programEditMessage: string;
  countNotification: number;
  addCalendarEvent: IEvent | null;
  changeCalendarEvent: IEvent | null;
  delCalendarEvent: IEvent | null;
  reloadCalendarEvents: boolean;
  updatedJob: ImportJob | null;

  creatingTestingFolder: ITestingFolder | null;
  createdTestingFolder: TestingFolder | null;
  updatingTestingFolder: ITestingFolder | null;
  updatedTestingFolder: TestingFolder | null;
  deletingTestingFolder: ITestingFolder | null;
  deletedTestingFolder: ITestingFolder | null;
  changingTesting: IChangingTesting | null;
  changedTesting: IChangedTesting | null;
  movingFolderId: string | null;
  movedFolder: IMovedFolder | null;
  selectedTestingIds: Set<string>;
  selectedTestingIdsSize: number;
  movingTestings: boolean | null;
  movedTestings: number | null;
  changingArchiveTestings: IChangeArchiveTesting | null;
  changedArchiveTestings: IChangeArchiveTesting | null;

  programSkillCategoryId: ESkillCategory | null;
  programStatusId: EProgramVersionStatus | null;

  changingAktQuestion: IChangingAktQuestion | null;
  changedAktQuestion: IChangedAktQuestion | null;
  removedAktQuestion: Pick<IChangedAktQuestion, "question_index"> | null;

  creatingGroupTask: ITaskGroupCreate | null;
  createdGroupTask: TaskTask[] | null;
  creatingIndividualTask: ITaskIndividualCreate | null;
  createdIndividualTask: TaskTask | null;
  updatingIndividualTask: ITaskUpdate | null;
  updatedIndividualTask: IUpdatedIndividualTask | null;
  deletingIndividualTask: ITaskDelete | null;
  deletedIndividualTask: ITaskDelete | null;

  changingAdmissionRole: IChangingAdmissionRole | null;
  changedAdmissionRole: IChangedAdmissionRole | null;
  deletingAdmissionRole: IDeletingAdmissionRole | null;
  deletedAdmissionRole: IDeletedAdmissionRole | null;

  changingSkillTemplate: IChangingSkillTemplate | null;
  changedSkillTemplate: IChangedSkillTemplate | null;
  deletingSkillTemplate: IDeletingSkillTemplate | null;
  deletedSkillTemplate: IDeletedSkillTemplate | null;

  changingStructureFactory: IChangingStructureFactory | null;
  changedStructureFactory: IChangedStructureFactory | null;
  deletedStructureFactory: IDeletedStructureFactory | null;
  changingStructureInfinity: IChangingStructureInfinity | null;
  changedStructureInfinity: IChangedStructureInfinity | null;
  deletedStructureInfinity: IDeletedStructureInfinity | null;
}

export default new Vuex.Store<IStoreState>({
  state: {
    deviceType: EDeviceTypes.desktop,
    showNavMenu: false,
    authorized: false,
    currentUser: null,
    currentUserPermissions: null,
    editTreeUser: null,
    editedTreeUser: null,
    factoryProgramId: null,
    openTreeProgram: new Map(),
    editTreeLevel: null,
    editedTreeLevel: null,
    editedTreeSkill: null,
    editedSkillId: null,
    editedSkillIdElement: null,
    editedSkillLevel: null,
    editedSkillPrefix: null,
    editedSkillReload: null,
    search: "",
    rangeDate: [],
    currentSkill: null,
    programEditMessage: "Черновик",
    countNotification: 0,
    addCalendarEvent: null,
    changeCalendarEvent: null,
    delCalendarEvent: null,
    reloadCalendarEvents: false,
    updatedJob: null,

    creatingTestingFolder: null,
    createdTestingFolder: null,
    updatingTestingFolder: null,
    updatedTestingFolder: null,
    deletingTestingFolder: null,
    deletedTestingFolder: null,
    changingTesting: null,
    changedTesting: null,
    movingFolderId: null,
    movedFolder: null,
    selectedTestingIds: new Set(),
    selectedTestingIdsSize: 0,
    movingTestings: null,
    movedTestings: null,
    changingArchiveTestings: null,
    changedArchiveTestings: null,

    programSkillCategoryId: null,
    programStatusId: null,

    changingAktQuestion: null,
    changedAktQuestion: null,
    removedAktQuestion: null,

    creatingGroupTask: null,
    createdGroupTask: null,
    creatingIndividualTask: null,
    createdIndividualTask: null,
    updatingIndividualTask: null,
    updatedIndividualTask: null,
    deletingIndividualTask: null,
    deletedIndividualTask: null,

    changingAdmissionRole: null,
    changedAdmissionRole: null,
    deletingAdmissionRole: null,
    deletedAdmissionRole: null,

    changingSkillTemplate: null,
    changedSkillTemplate: null,
    deletingSkillTemplate: null,
    deletedSkillTemplate: null,

    changingStructureFactory: null,
    changedStructureFactory: null,
    deletedStructureFactory: null,
    changingStructureInfinity: null,
    changedStructureInfinity: null,
    deletedStructureInfinity: null
  },
  mutations: {
    authorized(state, value: IStoreState["authorized"]) {
      state.authorized = value;
    },
    currentUser(state, value: IStoreState["currentUser"]) {
      state.currentUser = value;
    },
    currentUserPermissions(
      state,
      value: IStoreState["currentUserPermissions"]
    ) {
      state.currentUserPermissions = value;
    },
    editTreeUser(state, value: IStoreState["editTreeUser"]) {
      state.editTreeUser = value;
    },
    editedTreeUser(state, value: IStoreState["editedTreeUser"]) {
      state.editedTreeUser = value;
    },
    factoryProgramId(state, value: IStoreState["factoryProgramId"]) {
      state.factoryProgramId = value;
    },
    openTreeProgram(state, value: IStoreState["openTreeProgram"]) {
      state.openTreeProgram = value;
    },
    editTreeLevel(state, value: IStoreState["editTreeLevel"]) {
      state.editTreeLevel = value;
    },
    editedTreeLevel(state, value: IStoreState["editedTreeLevel"]) {
      state.editedTreeLevel = value;
    },
    editedTreeSkill(state, value: IStoreState["editedTreeSkill"]) {
      state.editedTreeSkill = value;
    },
    editedSkillId(state, value: IStoreState["editedSkillId"]) {
      state.editedSkillId = value;
    },
    editedSkillIdElement(state, value: IStoreState["editedSkillIdElement"]) {
      state.editedSkillIdElement = value;
    },
    editedSkillLevel(state, value: IStoreState["editedSkillLevel"]) {
      state.editedSkillLevel = value;
    },
    editedSkillPrefix(state, value: IStoreState["editedSkillPrefix"]) {
      state.editedSkillPrefix = value;
    },
    editedSkillReload(state, value: IStoreState["editedSkillReload"]) {
      state.editedSkillReload = value;
    },
    search(state, value: IStoreState["search"]) {
      state.search = value;
    },
    deviceType(state, value: IStoreState["deviceType"]) {
      state.deviceType = value;
    },
    showNavMenu(state, value: IStoreState["showNavMenu"]) {
      state.showNavMenu = value;
    },
    rangeDate(state, value: IStoreState["rangeDate"]) {
      state.rangeDate = value;
    },
    currentSkill(state, value: IStoreState["currentSkill"]) {
      state.currentSkill = value;
    },
    countNotification(state, value: IStoreState["countNotification"]) {
      state.countNotification = value;
    },
    programEditMessage(state) {
      state.programEditMessage = "Черновик сохранен";
    },
    programEditMessageRollback(state) {
      state.programEditMessage = "Черновик";
    },
    addCalendarEvent(state, value: IStoreState["addCalendarEvent"]) {
      state.addCalendarEvent = value;
    },
    changeCalendarEvent(state, value: IStoreState["changeCalendarEvent"]) {
      state.changeCalendarEvent = value;
    },
    delCalendarEvent(state, value: IStoreState["delCalendarEvent"]) {
      state.delCalendarEvent = value;
    },
    reloadCalendarEvents(state, value: IStoreState["reloadCalendarEvents"]) {
      state.reloadCalendarEvents = value;
    },
    importJobUpdated(state, value: IStoreState["updatedJob"]) {
      state.updatedJob = value;
    },

    creatingTestingFolder(state, value: IStoreState["creatingTestingFolder"]) {
      state.creatingTestingFolder = value;
    },
    createdTestingFolder(state, value: IStoreState["createdTestingFolder"]) {
      state.createdTestingFolder = value;
    },
    updatingTestingFolder(state, value: IStoreState["updatingTestingFolder"]) {
      state.updatingTestingFolder = value;
    },
    updatedTestingFolder(state, value: IStoreState["updatedTestingFolder"]) {
      state.updatedTestingFolder = value;
    },
    deletingTestingFolder(state, value: IStoreState["deletingTestingFolder"]) {
      state.deletingTestingFolder = value;
    },
    deletedTestingFolder(state, value: IStoreState["deletedTestingFolder"]) {
      state.deletedTestingFolder = value;
    },
    changingTesting(state, value: IStoreState["changingTesting"]) {
      state.changingTesting = value;
    },
    changedTesting(state, value: IStoreState["changedTesting"]) {
      state.changedTesting = value;
    },
    movingFolderId(state, value: IStoreState["movingFolderId"]) {
      state.movingFolderId = value;
    },
    movedFolder(state, value: IStoreState["movedFolder"]) {
      state.movedFolder = value;
    },
    toggleTestingId(state, value: string | null) {
      if (!value) {
        return;
      }

      if (state.selectedTestingIds.has(value)) {
        state.selectedTestingIds.delete(value);
      } else {
        state.selectedTestingIds.add(value);
      }

      state.selectedTestingIdsSize = state.selectedTestingIds.size;
    },
    clearSelectedTestingIds(state, _value?: void) {
      state.selectedTestingIds = new Set();
      state.selectedTestingIdsSize = 0;
    },
    movingTestings(state, value: IStoreState["movingTestings"]) {
      state.movingTestings = value;
    },
    movedTestings(state, value: IStoreState["movedTestings"]) {
      state.movedTestings = value;
    },
    changingArchiveTestings(
      state,
      value: IStoreState["changingArchiveTestings"]
    ) {
      state.changingArchiveTestings = value;
    },
    changedArchiveTestings(
      state,
      value: IStoreState["changedArchiveTestings"]
    ) {
      state.changedArchiveTestings = value;
    },
    programSkillCategoryId(
      state,
      value: IStoreState["programSkillCategoryId"]
    ) {
      state.programSkillCategoryId = value;
    },
    programStatusId(state, value: IStoreState["programStatusId"]) {
      state.programStatusId = value;
    },
    changingAktQuestion(state, value: IStoreState["changingAktQuestion"]) {
      state.changingAktQuestion = value;
    },
    changedAktQuestion(state, value: IStoreState["changedAktQuestion"]) {
      state.changedAktQuestion = value;
    },
    removedAktQuestion(state, value: IStoreState["removedAktQuestion"]) {
      state.removedAktQuestion = value;
    },
    creatingGroupTask(state, value: IStoreState["creatingGroupTask"]) {
      state.creatingGroupTask = value;
    },
    createdGroupTask(state, value: IStoreState["createdGroupTask"]) {
      state.createdGroupTask = value;
    },
    creatingIndividualTask(
      state,
      value: IStoreState["creatingIndividualTask"]
    ) {
      state.creatingIndividualTask = value;
    },
    createdIndividualTask(state, value: IStoreState["createdIndividualTask"]) {
      state.createdIndividualTask = value;
    },
    updatingIndividualTask(
      state,
      value: IStoreState["updatingIndividualTask"]
    ) {
      state.updatingIndividualTask = value;
    },
    updatedIndividualTask(state, value: IStoreState["updatedIndividualTask"]) {
      state.updatedIndividualTask = value;
    },
    deletingIndividualTask(
      state,
      value: IStoreState["deletingIndividualTask"]
    ) {
      state.deletingIndividualTask = value;
    },
    deletedIndividualTask(state, value: IStoreState["deletedIndividualTask"]) {
      state.deletedIndividualTask = value;
    },
    changingAdmissionRole(state, value: IStoreState["changingAdmissionRole"]) {
      state.changingAdmissionRole = value;
    },
    changedAdmissionRole(state, value: IStoreState["changedAdmissionRole"]) {
      state.changedAdmissionRole = value;
    },
    deletingAdmissionRole(state, value: IStoreState["deletingAdmissionRole"]) {
      state.deletingAdmissionRole = value;
    },
    deletedAdmissionRole(state, value: IStoreState["deletedAdmissionRole"]) {
      state.deletedAdmissionRole = value;
    },
    changingSkillTemplate(state, value: IStoreState["changingSkillTemplate"]) {
      state.changingSkillTemplate = value;
    },
    changedSkillTemplate(state, value: IStoreState["changedSkillTemplate"]) {
      state.changedSkillTemplate = value;
    },
    deletingSkillTemplate(state, value: IStoreState["deletingSkillTemplate"]) {
      state.deletingSkillTemplate = value;
    },
    deletedSkillTemplate(state, value: IStoreState["deletedSkillTemplate"]) {
      state.deletedSkillTemplate = value;
    },
    changingStructureFactory(
      state,
      value: IStoreState["changingStructureFactory"]
    ) {
      state.changingStructureFactory = value;
    },
    changedStructureFactory(
      state,
      value: IStoreState["changedStructureFactory"]
    ) {
      state.changedStructureFactory = value;
    },
    deletedStructureFactory(
      state,
      value: IStoreState["deletedStructureFactory"]
    ) {
      state.deletedStructureFactory = value;
    },
    changingStructureInfinity(
      state,
      value: IStoreState["changingStructureInfinity"]
    ) {
      state.changingStructureInfinity = value;
    },
    changedStructureInfinity(
      state,
      value: IStoreState["changedStructureInfinity"]
    ) {
      state.changedStructureInfinity = value;
    },
    deletedStructureInfinity(
      state,
      value: IStoreState["deletedStructureInfinity"]
    ) {
      state.deletedStructureInfinity = value;
    }
  },
  actions: {
    toggleProgramEditMessage(context) {
      context.commit("programEditMessage");

      setTimeout(() => {
        context.commit("programEditMessageRollback");
      }, 500);
    },
    updateCurrentUser(
      context,
      options: {
        authorized: IStoreState["authorized"];
        currentUser: IStoreState["currentUser"];
        currentUserPermissions: IStoreState["currentUserPermissions"];
      }
    ) {
      context.commit("authorized", options.authorized);
      context.commit("currentUser", options.currentUser);
      context.commit("currentUserPermissions", options.currentUserPermissions);
    },
    editedTreeUser(context, options: IStoreState["editedTreeUser"]) {
      context.commit("editTreeUser", null);
      context.commit("editedTreeUser", options);
    },
    editedTreeLevel(context, options: IStoreState["editedTreeLevel"]) {
      context.commit("editTreeLevel", null);
      context.commit("editedTreeLevel", options);
    }
  },
  getters: {
    current_user(state) {
      return state.currentUser;
    },
    current_user_permissions(state) {
      return state.currentUserPermissions;
    },
    current_user_write_permissions(state) {
      return state.currentUserPermissions?.filter(
        permission => permission.admission_level_id === EAdmissionLevel.WRITE
      );
    }
  },
  modules: {}
});
