import { EAdmissionPermission } from "@/models/admission/permission";
import { IRouteConfig } from "../interface";
import { programs_routes } from "./programs";
import { skills_routes } from "./skills";

const organization_child_routes: IRouteConfig[] = [
  {
    path: "/structures",
    name: "structures",
    meta: {
      name: "Орг. структура",
      check_jwt: true,
      permissions: [EAdmissionPermission.STRUCTURES]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/structures/Structures.vue"
      )
  },
  ...programs_routes,
  ...skills_routes
];

export const organization_routes: IRouteConfig[] = [
  {
    path: "/organization",
    name: "organization",
    meta: {
      name: "Организация",
      check_jwt: true,
      permissions: [
        EAdmissionPermission.STRUCTURES,
        EAdmissionPermission.EDUCATION_PROGRAMS,
        EAdmissionPermission.SKILLS,
        EAdmissionPermission.SKILL_SIGNS,
        EAdmissionPermission.INVENTORIES,
        EAdmissionPermission.PRIORITIES,
        EAdmissionPermission.BLOCKS
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Organization.vue"),
    children: organization_child_routes,
    redirect: { name: "structures" }
  }
];
