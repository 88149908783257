import Vue from "vue";
import VueRouter, { NavigationGuardNext } from "vue-router";

import notification_routes from "@/api/routes/notifications";

import { INavRouteConfig, IRouteConfig, IRouteMeta } from "./interface";
import { admin_routes } from "./admin";
import { developer_routes } from "./developer";
import { employees_routes } from "./employees";
import { analytics_routes } from "./analytics";
import { errors_routes } from "./other/errors";
import { auth_routes } from "./other/auth";
import { mobile_app_routes } from "./other/mobile_app";
import { organization_routes } from "./organizations";
import { testings_routes } from "./testings";
import { users_routes } from "./users";
import { akt_routes } from "./akt";
import { checkingRoute, getRoutesMeta } from "./parser";

Vue.use(VueRouter);

const routes: IRouteConfig[] = [
  {
    path: "/",
    name: "root",
    redirect: { name: "me_dashboard" },
    meta: {
      name: "Дашборд",
      check_jwt: true
    }
  },
  ...employees_routes,
  ...organization_routes,
  ...testings_routes,
  ...akt_routes,
  ...analytics_routes,
  ...users_routes,
  ...admin_routes,
  ...developer_routes,
  ...errors_routes,
  ...auth_routes,
  ...mobile_app_routes
];

export const routes_meta = getRoutesMeta(routes);

export const student_nav_routes: INavRouteConfig[] = [
  {
    redirect: { name: "me_dashboard" },
    meta: routes_meta["me_dashboard"]
  },
  {
    redirect: { name: "me_examination" },
    meta: routes_meta["me_examination"]
  },
  {
    redirect: { name: "calendar" },
    meta: routes_meta["calendar"]
  },
  {
    redirect: { name: "me_skills" },
    meta: routes_meta["me_skills"]
  },
  {
    redirect: { name: "me_catalog" },
    meta: routes_meta["me_catalog"]
  }
];

export const nav_routes: INavRouteConfig[] = [
  {
    redirect: { name: "me_dashboard" },
    meta: routes_meta["me_dashboard"]
  },
  {
    redirect: { name: "employees" },
    meta: routes_meta["employees"],
    children: [
      {
        redirect: { name: "team" },
        meta: routes_meta["team"]
      },
      {
        redirect: { name: "staffs_and_groups" },
        meta: routes_meta["staffs_and_groups"]
      },
      {
        redirect: { name: "positions" },
        meta: routes_meta["positions"]
      },
      {
        redirect: { name: "position_levels" },
        meta: routes_meta["position_levels"]
      },
      {
        redirect: { name: "responsible_task" },
        meta: routes_meta["responsible_task"]
      },
      {
        redirect: { name: "user_signs" },
        meta: routes_meta["user_signs"]
      }
    ]
  },
  {
    redirect: { name: "organization" },
    meta: routes_meta["organization"],
    children: [
      {
        redirect: { name: "structures" },
        meta: routes_meta["structures"]
      },
      {
        redirect: { name: "skills" },
        meta: routes_meta["skills"]
      },
      {
        redirect: { name: "skill_signs" },
        meta: routes_meta["skill_signs"]
      },
      {
        redirect: { name: "constructor" },
        meta: routes_meta["constructor"]
      },
      {
        redirect: { name: "priorities" },
        meta: routes_meta["priorities"]
      },
      {
        redirect: { name: "local_block" },
        meta: routes_meta["local_block"]
      },
      {
        redirect: { name: "inventories" },
        meta: routes_meta["inventories"]
      }
    ]
  },
  {
    redirect: { name: "test" },
    meta: routes_meta["test"],
    children: [
      {
        redirect: { name: "testings" },
        meta: routes_meta["testings"]
      },
      {
        redirect: { name: "testing_checks" },
        meta: routes_meta["testing_checks"]
      }
    ]
  },
  {
    redirect: { name: "analytical" },
    meta: routes_meta["analytical"],
    children: [
      {
        redirect: { name: "analytics" },
        meta: routes_meta["analytics"]
      },
      {
        redirect: { name: "supervisor_dashboard" },
        meta: routes_meta["supervisor_dashboard"]
      }
    ]
  },
  {
    redirect: { name: "akt" },
    meta: routes_meta["akt"],
    children: [
      {
        redirect: { name: "team" },
        meta: routes_meta["team"]
      },
      {
        redirect: { name: "akt_questions" },
        meta: routes_meta["akt_questions"]
      },
      {
        redirect: { name: "akt_templates" },
        meta: routes_meta["akt_templates"]
      },
      {
        redirect: { name: "akt_assignments" },
        meta: routes_meta["akt_assignments"]
      },
      {
        redirect: { name: "akt_passed_testing" },
        meta: routes_meta["akt_passed_testing"]
      }
    ]
  },
  {
    redirect: { name: "admin" },
    meta: routes_meta["admin"],
    children: [
      {
        redirect: { name: "admission_roles" },
        meta: routes_meta["admission_roles"]
      },
      {
        redirect: { name: "skill_templates" },
        meta: routes_meta["skill_templates"]
      }
    ]
  },
  {
    redirect: { name: "developer" },
    meta: routes_meta["developer"],
    children: [
      {
        redirect: { name: "banner_settings" },
        meta: routes_meta["banner_settings"]
      },
      {
        redirect: { name: "imported_files" },
        meta: routes_meta["imported_files"]
      },
      {
        redirect: { name: "mobile_app_versions" },
        meta: routes_meta["mobile_app_versions"]
      },
      {
        redirect: { name: "mobile_logs" },
        meta: routes_meta["mobile_logs"]
      }
    ]
  }
];

const router = new VueRouter({
  scrollBehavior() {
    const el = document.querySelector(".content-right");
    if (el) {
      el.scrollTop = 0;
    }
  },
  mode: "history",
  routes
});

export function useRouterMiddleware(app: Vue) {
  router.beforeResolve(async (to, from, next) => {
    if (to.matched.some(r => r.meta.check_jwt)) {
      if (!app.$api.getCurrentUser()) {
        await app.$api.reLoadCurrentUser();
      }

      if (
        !to.matched.some(r =>
          checkingRoute(
            r.meta as IRouteMeta,
            app.$api.getCurrentUser(),
            app.$api.getCurrentUserPermissions()
          )
        )
      ) {
        next({ name: "unknown" });
      }

      app.$api
        .get(notification_routes.notifications_count)
        .then(({ data: res }: { data: number }) => {
          app.$store.commit("countNotification", res);
        })
        .finally(() => {
          next();
        });
    } else {
      next();
    }
  });
}

export default router;
